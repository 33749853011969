import request from '@/utils/request'

export function search (data) {
  return request({
    url: '/courses/progress/search',
    method: 'post',
    data
  })
}
export function getPage (page, noitems) {
  return request({
    url: '/courses/progress/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function destroyProgress (id) {
  return request({
    url: '/courses/progress/' + id,
    method: 'delete'
  })
}
export function exportExcel (data) {
  return request({
    url: '/courses/progress/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
