<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" type="flex" justify="begin">
      <el-col :lg="12" :md="12" :sm="16" :xs="16" :offset="0">
      <el-input prefix-icon="el-icon-search" width="500" size="mini" placeholder="Buscar" v-model="searchForm.query" @change="handleSearch" clearable></el-input>
      </el-col>
      <el-button
        class="margin-left-20"
        @click.stop.prevent="handleSearch"
        type="primary"
        icon="el-icon-search"
        size="mini"
      >Buscar</el-button>
      <el-button
        class="margin-left-20"
        @click.stop.prevent="handleClean"
        type="warning"
        icon="el-icon-delete"
        size="mini"
      >Limpiar Filtros</el-button>
      <el-button
        class="margin-left-20"
        @click.stop.prevent="exportExcelFile"
        type="success"
        icon="el-icon-bottom"
        size="mini"
      >Exportar Excel</el-button>
    </el-row>
    <el-row :gutter="20">
      <el-col :lg="5" :sm="24" :offset="0">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Filtro</span> <br>
          <el-select
            size="mini"
            clearable
            v-model="searchForm.status"
            v-on:change="handleSearch()"
            filterable
            placeholder="Ninguno">
              <el-option
                v-for="itemPublication in publicationOptions"
                :key="itemPublication.value"
                :label="itemPublication.label"
                :value="itemPublication.value">
              </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :lg="5" :sm="24" :offset="0">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Curso</span> <br>
          <el-select
            size="mini"
            v-model="searchForm.course_id"
            filterable
            v-on:change="handleSearch()"
            clearable
            placeholder="titulo">
              <el-option
                v-for="itemCourses in listItemsCoursesTitle"
                :key="itemCourses.value"
                :label="itemCourses.title"
                :value="itemCourses._id">
              </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :lg="5" :sm="24" :offset="0">
        <div class="grid-content bg-purple">
          <span >Fecha inicial</span> <br>
          <el-date-picker
            @change="handleSearch()"
            size="mini"
            clearable
            v-model="searchForm.initDate"
            type="date"
            format="dd-MM-yyyy"
            placeholder="Mayo a ">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :lg="5" :sm="24" :offset="0">
        <div class="grid-content bg-purple">
          <span >Fecha final</span> <br>
          <el-date-picker
            @change="handleSearch()"
            clearable
            size="mini"
            v-model="searchForm.endDate"
            type="date"
            format="dd-MM-yyyy"
            placeholder="Menor a">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
      style="width: 100%"
    >
      <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
      <!-- <el-table-column label="ID" fixed width="100" align="center">
        <template slot-scope="scope" >
            <span>{{ scope.row._id}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="Título" fixed align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.title}}</span>
        </template>
      </el-table-column>
      <el-table-column label="No. Usuario" width="130" align="center" >
        <template slot-scope="scope" >
          <span>{{ scope.row.idEmployed}}</span>
         </template>
      </el-table-column>
      <el-table-column label="Nombre" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.nameEmployed}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Progreso" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.progress}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Completado" align="center">
        <div slot-scope="scope" v-if="scope.row.isCourseCompleted === true">
            Completado
        </div>
        <div v-else>
             No Completado
        </div>
      </el-table-column>
      <el-table-column label="Fecha Alta" width="100" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.created_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Actualización" width="100" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.updated_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Opciones" align="center" width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            round
            :loading="loading"
            @click.stop.prevent="handleDelete(scope.$index, scope.row)"
            >Eliminar</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { search, getPage, destroyProgress, exportExcel } from '@/api/progress'
import { getCourseTitle } from '@/api/listItems'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        initDate: '',
        endDate: '',
        status: '',
        course_id: ''
      },
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false,
      publicationOptions: [{
        value: 'true',
        label: 'Completado'
      }, {
        value: 'false',
        label: 'No Completado'
      }],
      listItemsCoursesTitle: []
    }
  },
  mounted () {
    this.handleSearch()
    this.listCoursesTitle()
  },
  methods: {
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.showItempageValue)
        .then(response => {
          this.list = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      await getPage(this.currentPage, val)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearch () {
      this.loading = true
      await search(this.searchForm).then(response => {
        this.list = response.data.data
        console.log(this.list)
        this.totalPages = response.data.total
      }).catch(this.responseCatch).finally(() => (this.loading = false))
    },
    handleDelete (index, row) {
      this.loading = true
      this.$confirm('Esto se eliminará de forma permanente. ¿Continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await destroyProgress(row._id)
            .then(response => {
              // let res = response.data
              this.$message({
                showClose: true,
                message: 'Sé elimino correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          })
        })
        .finally(() => (this.loading = false))
    },
    async listCoursesTitle () {
      this.loading = true
      await getCourseTitle()
        .then(response => {
          console.log(response)
          this.listItemsCoursesTitle = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async exportExcelFile () {
      this.loading = true
      await exportExcel(this.searchForm)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          const name = 'Reporte_de_progreso_de_cursos_' + new Date() + '.xlsx'
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.initDate = ''
      this.searchForm.endDate = ''
      this.searchForm.status = ''
      this.searchForm.course_id = ''
      this.handleSearch()
    }
  }
}
</script>
